import React from 'react'
import { LinkButton, PrimaryButton, Text, Icon, Box, Flex } from '@chilipiper/design-system/src'
import SvgCalendarWithDot from '@chilipiper/icons/src/CalendarWithDot'
import SvgLightning from '@chilipiper/icons/src/design-system/Lightning'
import SvgShuffle from '@chilipiper/icons/src/design-system/Shuffle'
import { VideoPlayer } from '../../components/VideoPlayer'
import videoUrl from './video.mp4'
import { CLOSED_DIALOG_ERROR, getHomebaseUrl, getViewFromUrl, openPopup } from '../../../helpers'
import { useGlobalContext } from '../../../context'
import { StorageLibrary } from '../../../storage'

export const Welcome = () => {
  const { setState } = useGlobalContext()
  const openLoginPopup = () => {
    openPopup(getHomebaseUrl(), (result: any) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const dialog = result.value
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (message: any) => {
          try {
            const data = JSON.parse(message.message)
            if (data.accessToken) {
              StorageLibrary.saveToLocalStorage('accessToken', data.accessToken)
              setState({ view: 'thread' })
              dialog.close()
            }
          } catch (err) {
            console.error(err)
          }
        })
        dialog.addEventHandler(Office.EventType.DialogEventReceived, (event: any) => {
          if (event.error === CLOSED_DIALOG_ERROR) {
            setState({ view: getViewFromUrl() })
          }
        })
      }
    })
  }
  return (
    <>
      <Box mt={6} />
      <VideoPlayer source={videoUrl} />
      <Box mt={6}>
        <Text fontWeight={600} fontSize={16}>
          Welcome to Instant Booker
        </Text>
        <Text as='p' color='neutral-dark-64' my={4} fontWeight={500} lineHeight='normal'>
          The Chili Piper add-in gives you the ability to book instant meetings straight from
          Outlook and suggest available times directly in the emails.
        </Text>
        <Box as='ul' my={6}>
          <Flex as='li' alignItems='center' mb={4}>
            <Icon icon={SvgCalendarWithDot} color='neutral-dark-80' />
            <Text ml={4} fontWeight={500} color='neutral-dark-64'>
              Schedule meetings in seconds
            </Text>
          </Flex>
          <Flex as='li' alignItems='center' mb={4}>
            <Icon icon={SvgLightning} color='neutral-dark-80' />
            <Text ml={4} fontWeight={500} color='neutral-dark-64'>
              Book 13x more meetings
            </Text>
          </Flex>
          <Flex as='li' alignItems='center'>
            <Icon icon={SvgShuffle} color='neutral-dark-80' />
            <Text ml={4} fontWeight={500} color='neutral-dark-64'>
              Automate your CRM data entry
            </Text>
          </Flex>
        </Box>
        <PrimaryButton mb={4} w='100%' onClick={() => openLoginPopup()}>
          Log in
        </PrimaryButton>
        <Flex justifyContent='center'>
          <Text fontWeight={500} color='neutral-dark-64' mr={2}>
            Don&apos;t have an account?
          </Text>
          <LinkButton
            as='a'
            href='https://chilipiper.com/request-demo?utm_medium=product&utm_source=vir&utm_campaign=outlookaddin&utm_content=welcome'
          >
            Book demo
          </LinkButton>
        </Flex>
      </Box>
    </>
  )
}
