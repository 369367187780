import React, { ReactNode, createContext, useContext } from 'react'
import { useSetState } from 'react-use'
import { getViewFromUrl, Views } from './helpers'

interface State {
  hasSession: boolean
  isSimpleAuth: boolean
  view: Views
}

interface GlobalStateValue {
  state: State
  setState: (state: Partial<State>) => void
}

export const GlobalContext = createContext({} as GlobalStateValue)

export const GlobalStateProvider = ({ children }: { children?: ReactNode }) => {
  const [state, setState] = useSetState<State>({
    hasSession: false,
    isSimpleAuth: false,
    view: getViewFromUrl(),
  })

  return <GlobalContext.Provider value={{ state, setState }}>{children}</GlobalContext.Provider>
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}
