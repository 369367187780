import React, { useEffect, useState } from 'react'
import { Box, Card, PrimaryButton, Radio, Text } from '@chilipiper/design-system'
import { fetchNoShowStatus, setNoShowStatus } from './../../services'
import { bookMeetingFromOrganizer } from '../../commands/commands'

interface NoShowProps {
  eventId: string
}

export const NoShow = ({ eventId }: NoShowProps) => {
  const [noShowOption, setNoShowOption] = useState<string | null>(null)

  useEffect(() => {
    fetchNoShowStatus(eventId).then(option => {
      if (option !== null) {
        setNoShowOption(option.toString())
      }
    })
  }, [])

  const handleNoShowOptionSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = e.currentTarget.value
    setNoShowOption(newOption)
    setNoShowStatus(eventId, newOption === 'true')
  }

  return (
    <>
      <Text as='h3' fontWeight={600} mb={3} mt={1}>
        Meeting Details
      </Text>
      <Card p={0} boxShadow='box-neutral-default-out' overflow='hidden'>
        <Box p={4}>
          <Text textStyle='page-heading'>Did your guest attend?</Text>
        </Box>
        <Radio
          checked={noShowOption === 'false'}
          id='noShow'
          label='No, my guest didn’t show'
          name='noShow'
          onChange={handleNoShowOptionSelected}
          value='false'
        />
        <Radio
          checked={noShowOption === 'true'}
          id='show'
          label='Yes, my guest showed up'
          name='show'
          onChange={handleNoShowOptionSelected}
          separator={false}
          value='true'
        />
      </Card>
      <Box my={6}>
        <Text textStyle='page-heading'>Book your follow-ups!</Text>
      </Box>
      <PrimaryButton w='100%' onClick={() => bookMeetingFromOrganizer()}>
        Schedule Next Meeting
      </PrimaryButton>
    </>
  )
}
