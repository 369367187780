import React, { ReactNode } from 'react'
import { animated, useTransition } from 'react-spring'

interface Props {
  children?: ReactNode
  show?: boolean
}

export const SlideIn: React.FC<Props> = props => {
  const transitions = useTransition(props.show, {
    from: {
      left: '100%',
    },
    enter: {
      left: '0%',
    },
    leave: {
      left: '-100%',
    },
  })
  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={{
            ...styles,
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
          }}
        >
          {props.children}
        </animated.div>
      )
  )
}
