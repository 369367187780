import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from '@chilipiper/design-system'
import { Session } from '@chilipiper/models/src/session'
import { AppContainer } from 'react-hot-loader'
import { GlobalStateProvider } from './context'
import { fetchReassignReport } from './services'

export const CLOSED_DIALOG_ERROR = 12006

export const render = (Component: any) => {
  const container = document.getElementById('container')
  if (container) {
    const root = createRoot(container)
    root.render(
      <GlobalStateProvider>
        <Provider>
          <AppContainer>
            <Component />
          </AppContainer>
        </Provider>
      </GlobalStateProvider>
    )
  }
}

export const isOutlookForMac = () => {
  return Office.context.diagnostics.platform === Office.PlatformType.Mac
}

export const isOutlookDesktop = () => {
  return Office.context.diagnostics.platform === Office.PlatformType.PC || isOutlookForMac()
}

export const closeTaskpane = () => {
  /*
    Office.addin does not work, so we can't use the hide() method
  */
  Office.context.ui.closeContainer()
}

export const showError = (message: string) => {
  Office.context.mailbox.item?.notificationMessages.replaceAsync('chilipiper-error', {
    type: 'errorMessage',
    message: message,
  })
}

export const showSuccess = (message: string) => {
  Office.context.mailbox.item?.notificationMessages.replaceAsync('chilipiper-information', {
    type: 'informationalMessage',
    message: message,
    icon: 'Icon.Success',
    persistent: false,
  })
}

export interface PopupParams {
  prospect?: string
  guests?: string
  bookWithMe?: boolean
  reportId?: string
}

export interface PopupSettings {
  dialogEventHandler?: (message: any) => void
  dialogMessageHandler?: (message: any) => void
  event?: Office.AddinCommands.Event
}

export const openIBPopup = (params: PopupParams, settings: PopupSettings = {}) => {
  const linkParams = Object.entries(params).reduce((prev, current) => {
    prev.set(current[0], current[1])
    return prev
  }, new URLSearchParams())
  return new Promise<any>((resolve, reject) => {
    const dialogLink = `${getAddingUrl()}/dialog.html?${linkParams.toString()}&isOutlook=true`

    Office.context.ui.displayDialogAsync(
      dialogLink,
      {
        height: 90,
        width: 90,
        promptBeforeOpen: false,
        displayInIframe: true,
      },
      result => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = result.value

          dialog.addEventHandler(Office.EventType.DialogEventReceived, event => {
            if (settings.dialogEventHandler) {
              settings.dialogEventHandler(event)
            }

            if ((event as any).error === CLOSED_DIALOG_ERROR) {
              settings.event?.completed()
            }
          })

          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (message: any) => {
            if (message.message) {
              const event = JSON.parse(message.message)
              if (settings.dialogEventHandler) {
                settings.dialogEventHandler(event)
              }
              if (event.action === 'meetingDidBook') {
                dialog.close()
                showSuccess('Meeting booked')
                settings.event?.completed()
              }
              /*
                Availability inside iframe needs to send message to parent window
              */
              if (event.action === 'insertAvailability') {
                window.postMessage(event, '*')
                dialog.close()
                settings.event?.completed()
              }
            }
          })
          resolve(result)
        } else {
          showError(result.error.message)
          reject(result)
        }
      }
    )
  })
}

export const openPopup = (url: string, callback?: (result?: any) => void) => {
  Office.context.ui.displayDialogAsync(url, { promptBeforeOpen: false }, callback)
}

export type Views = 'compose' | 'thread' | 'organizer' | 'attendee' | 'welcome'

export const getViewFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('view') as Views
}

const getEventIdFromOutlookItem = (itemId: string) => {
  const eventId = Office.context.mailbox.convertToRestId(
    itemId,
    Office.MailboxEnums.RestVersion.v2_0
  )
  return eventId
}

export const findReassignReport = () => {
  return new Promise<{ eventId: string; reportId: string }>((resolve, reject) => {
    const itemId = Office.context.mailbox.item?.itemId
    if (!itemId) {
      Office.context.mailbox.item?.getItemIdAsync(itemData => {
        const eventId = getEventIdFromOutlookItem(itemData.value)
        fetchReassignReport(getEventIdFromOutlookItem(itemData.value))
          .then(reportId => {
            resolve({ eventId, reportId })
          })
          .catch(reject)
      })
    } else {
      const eventId = getEventIdFromOutlookItem(itemId)
      fetchReassignReport(getEventIdFromOutlookItem(itemId))
        .then(reportId => {
          resolve({ eventId, reportId })
        })
        .catch(reject)
    }
  })
}

export const saveMailboxItem = (callback: () => void) => {
  const itemId = Office.context.mailbox.item?.itemId
  if (!itemId) {
    Office.context.mailbox.item?.saveAsync(callback)
  } else {
    callback()
  }
}

export const logout = () => {
  Session.logout()
  closeTaskpane()
}

export const getHomebaseUrl = () => {
  return process.env.REACT_APP_REVERSE_PROXY || ''
}

export const getAddingUrl = () => {
  return process.env.REACT_APP_ADD_IN_RESOURCE || ''
}
