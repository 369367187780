import { get } from '@chilipiper/service/src/api'

export const fetchReassignReport = (eventId: string) => {
  return get(`reassign/find-report/${eventId}`).json<string>()
}

export const fetchNoShowStatus = (eventId: string) => {
  return get(`no-show/event/${eventId}`)
    .json<{ attended: boolean }>()
    .then(eventData => {
      return eventData.attended
    })
}

export const setNoShowStatus = (eventId: string, showed: boolean) => {
  return get(`no-show/set/${eventId}/${showed}`)
}
