export class StorageLibrary {
  static saveToPropertyBag(key: string, value: any): void {
    if (Office.context.document.settings) {
      Office.context.document.settings.set(key, value)
    } else {
      const unsupportedError = new Error(
        'Feature not supported: The settings object is not supported in this host application.'
      )
      throw unsupportedError
    }
  }

  static getFromPropertyBag(key: string): any {
    if (Office.context.document.settings) {
      const value = Office.context.document.settings.get(key)
      return value
    } else {
      const unsupportedError = new Error(
        'Feature not supported: The settings object is not supported in this host application.'
      )
      throw unsupportedError
    }
  }

  static saveToBrowserCookies(key: string, value: any): void {
    document.cookie = `${key}=${value}`
  }

  static getFromBrowserCookies(key: string): string | null {
    const all = document.cookie

    if (all === '') {
      return null
    } else {
      const list = all.split('; ')
      let value: string | null = null

      for (const cookie of list) {
        const [name, val] = cookie.split('=')

        if (name === key) {
          value = val
          break
        }
      }

      return value
    }
  }

  static saveToLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, value)
  }

  static getFromLocalStorage(key: string): string | null {
    const value = localStorage.getItem(key)
    return value
  }

  static saveToSessionStorage(key: string, value: any): void {
    sessionStorage.setItem(key, value)
  }

  static getFromSessionStorage(key: string): string | null {
    const value = sessionStorage.getItem(key)
    return value
  }

  static saveToDocument(key: string, value: any): void {
    let hiddenStorage = document.getElementById('hiddenstorage') as HTMLDivElement | null

    if (!hiddenStorage) {
      hiddenStorage = document.createElement('div')
      hiddenStorage.id = 'hiddenstorage'
      hiddenStorage.style.display = 'none'
      document.body.appendChild(hiddenStorage)
    }

    const keyNode = document.createElement('span')
    keyNode.id = key

    const valueNode = document.createTextNode(value)
    keyNode.appendChild(valueNode)

    hiddenStorage.appendChild(keyNode)
  }

  static getFromDocument(key: string): string | null {
    const valueNode = document.getElementById(key)

    if (valueNode) {
      return valueNode.innerHTML
    }

    return null
  }
}
