import React, { useEffect, useState } from 'react'
import { Box, PrimaryButton, Text } from '@chilipiper/design-system'
import { bookMeetingFromOrganizer } from '../../commands/commands'
import { findReassignReport } from '../../helpers'

interface ValidateEventProps {
  component: React.ComponentType<{ eventId: string; reportId: string }>
}

export const ValidateEvent = (props: ValidateEventProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [eventId, setEventId] = useState('')
  const [reportId, setReportId] = useState('')

  useEffect(() => {
    findReassignReport()
      .then(({ eventId, reportId }) => {
        if (reportId) {
          setReportId(reportId)
          setEventId(eventId)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      {isLoading ? (
        <>
          <Text fontWeight={600} fontSize={16}>
            Validating event
          </Text>
          <Box mt={2}>
            <Text color='neutral-dark-64'>
              Checking if this event was booked with Chili Piper...
            </Text>
          </Box>
        </>
      ) : reportId ? (
        <props.component eventId={eventId} reportId={reportId} />
      ) : (
        <>
          <Text fontWeight={600} fontSize={16}>
            Not booked with Chili Piper
          </Text>
          <Box mt={2} mb={6}>
            <Text color='neutral-dark-64'>
              Looks like this event wasn’t booked with Chili Piper. You can schedule your next
              meeting or return to the home page.
            </Text>
          </Box>
          <PrimaryButton w='100%' onClick={() => bookMeetingFromOrganizer()}>
            Schedule Next Meeting
          </PrimaryButton>
        </>
      )}
    </>
  )
}
