import React, { useState } from 'react'
import { Box, Flex, PrimaryButton, Button, Card, Text, TextField } from '@chilipiper/design-system'
import { BookingLinksSection } from '@chilipiper/meetings-components/src/booking-links/components/booking-link-section/BookingLinksSection'
import { Session } from '@chilipiper/models/src/session'
import { QueryClientProvider, QueryClient } from 'react-query'
import { bookMeeting, insertAvailability } from '../../commands/commands'
import { openIBPopup, showSuccess } from '../../helpers'
import { useGlobalContext } from '../../context'

const queryClient = new QueryClient()

interface Props {
  withInsertAvailability?: boolean
}

export const QuickActions = ({ withInsertAvailability }: Props) => {
  const [prospectEmail, setProspectEmail] = useState('')
  const { state } = useGlobalContext()

  const copyLink = (link = '') => {
    const input = document.createElement('input')
    input.type = 'text'
    input.value = link
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    input.remove()
  }

  const bookMettingFromEmail = (e: any) => {
    e.preventDefault()
    openIBPopup({ prospect: prospectEmail })
    setProspectEmail('')
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Box display='flex' flexDirection='column' gridGap={2}>
        <Text fontWeight={600}>Quick Actions</Text>

        {withInsertAvailability && (
          <PrimaryButton w='100%' onClick={() => insertAvailability()}>
            Insert Suggested Times
          </PrimaryButton>
        )}
        <Button
          w='100%'
          onClick={() => {
            bookMeeting()
          }}
        >
          Book a Meeting
        </Button>

        <Box my={2}>
          <Card as='form' onSubmit={bookMettingFromEmail}>
            <Text fontWeight={500}>
              Enter guest emails to book a meeting. You will be redirected to select appropriate
              meeting time.
            </Text>
            <Flex mt={2}>
              <TextField
                h={2}
                enterKeyHint='done'
                placeholder='Email'
                type='email'
                onChange={e => setProspectEmail(e.target.value)}
              />
              <Button color='teal-light-100' ml={2} h={9}>
                Book
              </Button>
            </Flex>
          </Card>
        </Box>
        {!state.isSimpleAuth && (
          <BookingLinksSection
            onCopyLinkClicked={(_, link) => {
              copyLink(link)
              showSuccess('Link copied')
            }}
            session={Session}
          />
        )}
      </Box>
    </QueryClientProvider>
  )
}

export default QuickActions
