import React, { useEffect, useState } from 'react'
import { Box, Flex, DotsLoader } from '@chilipiper/design-system'
import { useGlobalContext } from '../context'
import { Session } from '@chilipiper/models/src/session'
import { Welcome } from './views/welcome/Welcome'
import { Compose } from './views/compose/Compose'
import { NoShow } from './views/NoShow'
import { SlideIn } from './components/SlideIn'
import { Thread } from './views/thread/Thread'
import { Attendee } from './views/Attendee'
import { ValidateEvent } from './components/ValidateEvent'
import { isOutlookDesktop, Views } from '../helpers'
import { Settings } from './components/Settings'
import { StorageLibrary } from '../storage'

type ViewComponent = {
  [key in Views]: React.ComponentType
}

const viewComponent: ViewComponent = {
  welcome: Welcome,
  compose: Compose,
  thread: Thread,
  attendee: () => <ValidateEvent component={Attendee} />,
  organizer: () => <ValidateEvent component={NoShow} />,
}

export const App = () => {
  const { state, setState } = useGlobalContext()
  const [loadingSession, setLoadingSession] = useState(true)
  const isLoading = loadingSession

  useEffect(() => {
    async function loadSession() {
      setLoadingSession(true)

      try {
        if (isOutlookDesktop()) {
          const accessToken = StorageLibrary.getFromLocalStorage('accessToken')
          if (!accessToken) {
            throw new Error('No access token cookie')
          }
        }
        await Session.createSession().then(() => {
          setState({ hasSession: true, isSimpleAuth: false })
        })
      } catch (err) {
        console.error(err)
        setState({ view: 'welcome' })
      } finally {
        setLoadingSession(false)
      }
    }
    loadSession()
  }, [state.view])

  const Component = viewComponent[state.view]

  return (
    <Box h='100%' overflow='hidden'>
      <SlideIn show={isLoading}>
        <Flex h='100%' w='100%' alignItems='center' justifyContent='center' bg='bg/moderate'>
          <DotsLoader />
        </Flex>
      </SlideIn>
      <SlideIn show={!isLoading}>
        <Flex flexDirection='column' bg='bg/moderate' h='100%'>
          <Box p={5} h='100%' flex={1} overflow='auto'>
            <Component />
          </Box>
          {state.view !== 'welcome' && (
            <Flex justifyContent='end' px={5} pb={2}>
              <Settings />
            </Flex>
          )}
        </Flex>
      </SlideIn>
    </Box>
  )
}

export default App
