import React, { useEffect, useRef, useState } from 'react'
import { Box, UnstyledButton, Icon, styled, css } from '@chilipiper/design-system'
import { SvgPlay, SvgPause } from '@chilipiper/icons/src/design-system'

interface Props {
  source: string
}

const PlayButton = styled(UnstyledButton)`
  display: flex;
  width: 48px;
  height: 48px;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  border-radius: 50%;
  background-color: rgba(38, 52, 64, 0.32);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
`

const Wrapper = styled(Box)<{ isPlaying?: boolean }>`
  position: relative;

  video {
    border-radius: 8px;
    width: 100%;
  }

  &:hover {
    ${PlayButton} {
      opacity: 1;
    }
  }

  ${props =>
    !props.isPlaying &&
    css`
      ${PlayButton} {
        opacity: 1;
      }
    `}
`

export const VideoPlayer = (props: Props) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
  }, [isPlaying])

  return (
    <Wrapper isPlaying={isPlaying}>
      <PlayButton onClick={() => setIsPlaying(!isPlaying)}>
        {isPlaying ? <Icon icon={SvgPause} /> : <Icon icon={SvgPlay} />}
      </PlayButton>
      <video ref={videoRef} onEnded={() => setIsPlaying(false)}>
        <source src={props.source} type='video/mp4' />
      </video>
    </Wrapper>
  )
}
