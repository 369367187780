import React, { useState } from 'react'
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverTarget,
  UnstyledButton,
  styled,
  Card,
  Icon,
} from '@chilipiper/design-system'
import { SvgSettings } from '@chilipiper/icons'
import { SvgArrowTopRight } from '@chilipiper/icons/src/design-system'
import { getHomebaseUrl, logout } from '../../helpers'
import { useGlobalContext } from '../../context'
import { StorageLibrary } from '../../storage'

const HoverContainer = styled(Flex)<{ active?: boolean }>`
  cursor: pointer;
  background: ${props => (props.active ? props.theme.colors['neutral-dark-8'] : 'transparent')};

  &:hover {
    background: ${props => props.theme.colors['neutral-dark-8']};
  }
`

export const Settings = () => {
  const { setState } = useGlobalContext()
  const [isOpen, setIsOpen] = useState(false)

  const openPopupAndClose = (url: string) => {
    window.open(`${getHomebaseUrl()}${url}`)
    setIsOpen(false)
  }

  return (
    <Popover zIndex={10} placement='top-start'>
      <PopoverTrigger w='auto' display='inline-block'>
        <HoverContainer
          w={7}
          h={7}
          borderRadius={8}
          active={isOpen}
          justifyContent='center'
          alignItems='center'
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon icon={SvgSettings} w={4} h={4} color='neutral-dark-80' pointer />
        </HoverContainer>
      </PopoverTrigger>

      {isOpen && (
        <PopoverTarget onOutsideClick={() => setIsOpen(false)}>
          <Card
            w='200px'
            m={0}
            p={0}
            px={4}
            display='flex'
            flexDirection='column'
            position='relative'
          >
            <UnstyledButton
              py={3}
              fontWeight={500}
              boxShadow='border/row'
              display='flex'
              justifyContent='space-between'
              onClick={() => openPopupAndClose('/meetings/templates')}
            >
              Meeting Types <Icon icon={SvgArrowTopRight} />{' '}
            </UnstyledButton>
            <UnstyledButton
              py={3}
              fontWeight={500}
              boxShadow='border/row'
              display='flex'
              justifyContent='space-between'
              onClick={() => openPopupAndClose('/meetings/personal-settings/personal-details')}
            >
              Personal Details <Icon icon={SvgArrowTopRight} />{' '}
            </UnstyledButton>
            <UnstyledButton
              py={3}
              fontWeight={500}
              boxShadow='border/row'
              display='flex'
              justifyContent='space-between'
              onClick={() => openPopupAndClose('/meetings/personal-settings/working-hours')}
            >
              Working Hours <Icon icon={SvgArrowTopRight} />{' '}
            </UnstyledButton>
            <UnstyledButton
              py={3}
              fontWeight={500}
              boxShadow='border/row'
              display='flex'
              justifyContent='space-between'
              onClick={() => openPopupAndClose('/meetings/personal-settings/integrations')}
            >
              My Integrations <Icon icon={SvgArrowTopRight} />{' '}
            </UnstyledButton>
            <UnstyledButton
              py={3}
              fontWeight={500}
              color='red-dark-100'
              onClick={() => {
                setState({ hasSession: false })
                StorageLibrary.saveToLocalStorage('accessToken', '')
                logout()
              }}
            >
              Log Out
            </UnstyledButton>
          </Card>
        </PopoverTarget>
      )}
    </Popover>
  )
}
