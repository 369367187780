/* eslint-env node */
import { App } from './App'
import './taskpane.css'
import { render } from '../helpers'

/* Render application after Office initializes */
Office.onReady(() => {
  render(App)
})

if ((module as any).hot) {
  ;(module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
