import { useEffect } from 'react'
import { openReassignPopup } from '../../commands/commands'

interface AttendeeProps {
  reportId: string
}

export const Attendee = (props: AttendeeProps) => {
  useEffect(() => {
    openReassignPopup(props.reportId)
  }, [])

  return null
}
